@tailwind base;
@tailwind components;
@tailwind utilities;

@import './fonts/index.css';

@layer base {
  html {
    font-family: roboto, sans-serif;
    scroll-behavior: smooth;
    @apply tabular-nums;
  }

  input textarea {
    @apply placeholder:text-white/30;
  }
}

@layer components {
}

.funcBG {
  @apply bg-light-4/10 backdrop-blur-sm;
}

.pageTitle {
  @apply text-light-4 font-bold text-center 
          text-[32px] md:text-[40px] 2xl:text-5xl 
          pb-10 md:pb-[56px];
}

.scrollbarHidden {
  @apply scrollbar-none;
}

.scrollbar {
  @apply scrollbar-thin 
          scrollbar-thumb-dark-5
          hover:scrollbar-thumb-light-5
          scrollbar-thumb-rounded-full 
          scrollbar-track-black/10 
          scrollbar-track-rounded-full;
}

.floating {
  max-width: calc(100vw - 10px);
  background: rgba(255, 1, 1, 0.3);
}

.swiper-pagination-bullet {
  opacity: 100% !important;
  width: 10px !important;
  height: 10px !important;
  background: rgba(40, 202, 206, 0.25) !important;
}

.swiper-pagination-bullet-active {
  opacity: 100% !important;
  width: 10px !important;
  height: 10px !important;
  background: rgba(40, 202, 206, 0.75) !important;
}

.dashedDivide {
  background-image: linear-gradient(to right, #4f4f4f 40%, #222 20%);
  background-position: top;
  background-size: 10px 1px;
  background-repeat: repeat-x;
}

body {
  @apply scrollbar min-h-screen h-full bg-dark-1;
}

#root {
  @apply min-h-screen h-full;
}

/* 88px 是上下 padding */
.sectionHeight {
  min-height: calc(100vh - 88px - 88px);
}
