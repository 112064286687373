@font-face {
  font-family: 'roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('roboto'), url('./Roboto-Regular.woff2') format('woff2');
  unicode-range: U+000-5FF; /* Latin glyphs */
}

@font-face {
  font-family: 'roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local('roboto'), url('./Roboto-Medium.woff2') format('woff2');
  unicode-range: U+000-5FF; /* Latin glyphs */
}

@font-face {
  font-family: 'roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('roboto'), url('./Roboto-Bold.woff2') format('woff2');
  unicode-range: U+000-5FF; /* Latin glyphs */
}
